import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import styled, { useTheme } from 'styled-components'
import { H2, Link, BGBlocks } from '../../../UI'
import SectionHeading from '../Section.Heading'

const Section = styled.section`
	/* background: ${props => props.theme.colors.skyBlue.light('1').desat('5').val}; */
  background: #9BB9BC;
  .inner{
	padding: 22px ${props => props.theme.sizes.gutter.mobile.px};
	position: relative;
	${props => props.theme.media.sdesk} {
		padding: 122px ${props => props.theme.sizes.gutter.sdesk.px};
		overflow: hidden;
	}
  }
	h2 {
		color: ${props => props.theme.colors.white.val};
		font-size: 2.4rem;
		letter-spacing: -3.2px;
		padding-bottom: 22px;
		font-weight: 100;
		text-align: left;
		${props => props.theme.media.sdesk} {
			font-size: 4rem;
		}
	}
	.cards-wrap {
		position: relative;
		max-width: 1500px;
		margin: 0 auto 25px auto;
		align-items: center;
		flex-direction: column;

		${props => props.theme.media.sdesk} {
			flex-direction: row;
			margin: 0 auto 50px auto;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-rows: 1fr 1fr;
		}

		.card {
			font-size: 1.1rem;
			letter-spacing: 0.48px;
			/* padding: 20px 20px; */
			${props => props.theme.media.tablet} {
			max-width: 550px;
				font-size: 1.5em;
				line-height: 30px;
				padding: 40px 60px;
				max-width: 350px;
			}

			${props => props.theme.media.sdesk} {
				font-size: 1.2rem;
				line-height: 1.4rem;
				padding: 3rem 3.5rem;
				max-width: 30vw;
			}
			b {
				font-weight: 600;
			}
			&:nth-of-type(1) {
				/* background: ${props => props.theme.colors.orange.light('4').desat('2').val}; */
				z-index: 4;
				align-self: flex-start;
				${props => props.theme.media.tablet} {
        background:#EDDCCD;
				box-shadow: 5px 5px 7px 0px ${props => props.theme.colors.dkOrange.desat('2').val};
					
				}
			}
			&:nth-of-type(2) {
				/* background: ${props => props.theme.colors.orange.light('3').desat('2').val}; */
				z-index: 3;
				align-self: flex-end;

				${props => props.theme.media.tablet} {
        background: #e6c9b9;
				box-shadow: 5px 5px 7px 0px ${props => props.theme.colors.dkOrange.dark('1').desat('2').val};
					align-self: flex-start;
					margin: -200px 0 0 320px;
					z-index: 2;
				}

				${props => props.theme.media.sdesk} {
					margin: 0;
					/* margin: 150px 0 0 -2vw; */
					grid-column: 2;
					grid-row: 1;
					/* margin: 10vh 0 0 -25px; */
					transform: translate3d(-25px,40%, 0);
				}
				${props => props.theme.media.ldesk} {
					/* margin: 200px 0 0 -40px; */
				}
			}
			&:nth-of-type(3) {
				/* background: ${props => props.theme.colors.skyBlue.light('4').desat('1').val}; */
				z-index: 1;
				align-self: flex-start;
				${props => props.theme.media.tablet} {
        background:#dab19a;
				box-shadow: 5px 5px 7px 0px #614737;
					margin: -50px 0 0 0;
					z-index: 3;
				}

				${props => props.theme.media.sdesk} {
					margin: 0;
					/* margin: -100px 0 0 -3vw; */
					grid-column: 3;
					grid-row: 1;
					/* margin: -40px 0 0 -25px; */
					transform: translate3d(-80px, -30%, 0);
				}
				${props => props.theme.media.ldesk} {
					/* margin: -100px 0 0 -80px; */
				}
			}
			&:nth-of-type(4) {
				/* background: ${props => props.theme.colors.skyBlue.light('2').desat('1').val}; */
				z-index: 2;
				align-self: flex-end;

				${props => props.theme.media.tablet} {
        background: #c79b7f;
				box-shadow: 5px 5px 7px 0px #3a2d23;
					align-self: flex-start;
					margin: -100px 0 0 320px;
					z-index: 1;
				}

				${props => props.theme.media.sdesk} {
					margin: 0;
					/* margin: 50vh 0 0 -25vw; */
					grid-column: 3;
					grid-row: 2;
					/* margin: -20vh 0 0 -25px; */
					transform: translate3d(-50px, 0, 0);
				}
				${props => props.theme.media.ldesk} {
					/* margin: 545px 0 0 -500px; */
				}
			}
		}
	}
  .cta-wrap{
    margin:0 auto;
    text-align: center;
    z-index:1;
			margin-top: 2rem;
		${props => props.theme.media.tablet} {
		}
    ${props => props.theme.media.sdesk} {
      text-align: left;
			margin-top: 10vh;
    }
    .link {
		position: relative;
		${props => props.theme.media.sdesk} {
			font-size: 1.3rem;
		}
	}
  }

	svg {
		display: block;
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 0;
		${props => props.theme.media.sdesk} {
			width: 50%;
		}
	}
	.bg-blocks {
		fill: ${props => props.theme.colors.slate.light('5').val};
		opacity: 0.4;
	}
`

const parseHeaderBgColor = (color, colors) =>
	color === 'Dark Slate'
		? colors.slate.val
		: color === 'Light Slate'
		? colors.heroSlate.val
		: colors.slate.val // this is the default

const FourCardLong = ({ data, className }) => {
	const { sectionHeading, headerBgColor, headline, link, cards } = data
	const { colors } = useTheme()
	const parsedHeaderBgColor = parseHeaderBgColor(headerBgColor, colors)
	return (
		<Section className={`cta-fourcard-section${className ? ` ${className}` : ''}`}>
			<SectionHeading headerBgColor={parsedHeaderBgColor}>{sectionHeading}</SectionHeading>
			<BGBlocks />
			<div className={'inner'}>
				<H2>{headline}</H2>
				<div className={`cards-wrap flex`}>
					{cards.map(card => (
						<div key={card.id} className={`card`}>
							<p>
								<b>{card.title}</b>
								<br></br>
								{card.text.text}
							</p>
						</div>
					))}
					<div className='cta-wrap'>
						{get(link, 'link') && (
							<Link isCta isWhite to={link.link}>
								{link.title || link.link}
							</Link>
						)}
					</div>
				</div>
			</div>
		</Section>
	)
}
FourCardLong.propTypes = {
	data: PropTypes.shape({
		headline: PropTypes.string.isRequired,
	}),
}
export default FourCardLong
