import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
// import styled from 'styled-components'

import Hero from './Hero/Hero'
import CTA from './Section.CTA/Section.CTA'
import AccordionLong from './Accordion/Accordion.Long'
import AccordionSqueeze from './Accordion/Accordion.Squeeze'
import CampusesLong from './Campuses/Campuses.Long'
import CampusesSqueeze from './Campuses/Campuses.Squeeze'
import FourCardLong from './FourCard/FourCard.Long'
import FourCardSqueeze from './FourCard/FourCard.Squeeze'
import HubspotLong from './Hubspot/Hubspot.Long'
import HubspotSqueeze from './Hubspot/Hubspot.Squeeze'
import IconsLong from './Icons/Icons.Long'
import IconsSqueeze from './Icons/Icons.Squeeze'
import LogosLong from './Logos/Logos.Long'
import LogosSqueeze from './Logos/Logos.Squeeze'
import RatingLong from './Rating/Rating.Long'
import RatingSqueeze from './Rating/Rating.Squeeze'
import VideoLong from './Video/Video.Long'
import VideoSqueeze from './Video/Video.Squeeze'
import RichTextLong from './RichText/RichText.Long'
import RichTextSqueeze from './RichText/RichText.Squeeze'
import ProfilesLong from './Profiles/Profiles.Long'
import ProfilesSqueeze from './Profiles/Profiles.Squeeze'
import ContactInfoLong from './Contact/ContactInfo.Long'
import ContactInfoSqueeze from './Contact/ContactInfo.Squeeze'
import LinkCtaLong from './Link/Link.Long'
import LinkCtaSqueeze from './Link/Link.Squeeze'

// import { H2, EM, UL } from '../../UI'

// const S = styled.section`
// 	min-height: 50vh;
// 	align-items: center;
// 	justify-content: center;
// 	flex-direction: column;
// 	h2,
// 	em {
// 		color: ${props => props.theme.colors.err.val};
// 		text-transform: uppercase;
// 	}
// `

// const NoSection = ({ data }) => {
// 	console.log(
// 		'%cInvalid section data: ',
// 		`color:#FFF; background-color:#dc3300; padding: 4px;`,
// 		data
// 	)
// 	const heading = get(
// 		data,
// 		'heading',
// 		get(data, 'headingTop', get(data, 'bigHeading', get(data, 'title', get(data, 'headingBottom'))))
// 	)
// 	return (
// 		<S className='no-section flex'>
// 			<H2>
// 				<EM>NO SECTION COMPONENT FOUND...</EM>
// 			</H2>
// 			<UL>
// 				{heading && <li>Heading: "{heading}"</li>}
// 				<li>Section Type: {get(data, 'internal.type')}</li>
// 			</UL>
// 		</S>
// 	)
// }

const parseSectionType = (sectionData, pageType) => {
	const type = get(sectionData, 'internal.type')

	if (type === 'ContentfulSectionHero') {
		if (pageType === 'Long Form' || 'Squeeze') {
			return Hero
		}
	} else if (type === 'ContentfulSectionCta') {
		return CTA
	} else if (type === 'ContentfulAccordion') {
		if (pageType === 'Long Form') {
			return AccordionLong
		} else if (pageType === 'Squeeze') {
			return AccordionSqueeze
		} else {
			return null
		}
	} else if (type === 'ContentfulSectionCampuses') {
		if (pageType === 'Long Form') {
			return CampusesLong
		} else if (pageType === 'Squeeze') {
			return CampusesSqueeze
		} else {
			return null
		}
	} else if (type === 'ContentfulFourCardSection') {
		if (pageType === 'Long Form') {
			return FourCardLong
		} else if (pageType === 'Squeeze') {
			return FourCardSqueeze
		} else {
			return null
		}
	} else if (type === 'ContentfulSectionHubspot') {
		if (pageType === 'Long Form') {
			return HubspotLong
		} else if (pageType === 'Squeeze') {
			return HubspotSqueeze
		} else {
			return null
		}
	} else if (type === 'ContentfulSectionIcons') {
		if (pageType === 'Long Form') {
			return IconsLong
		} else if (pageType === 'Squeeze') {
			return IconsSqueeze
		} else {
			return null
		}
	} else if (type === 'ContentfulSectionLogos') {
		if (pageType === 'Long Form') {
			return LogosLong
		} else if (pageType === 'Squeeze') {
			return LogosSqueeze
		} else {
			return null
		}
	} else if (type === 'ContentfulSectionRating') {
		if (pageType === 'Long Form') {
			return RatingLong
		} else if (pageType === 'Squeeze') {
			return RatingSqueeze
		} else {
			return null
		}
	} else if (type === 'ContentfulSectionVideo') {
		if (pageType === 'Long Form') {
			return VideoLong
		} else if (pageType === 'Squeeze') {
			return VideoSqueeze
		} else {
			return null
		}
	} else if (type === 'ContentfulRichText') {
		if (pageType === 'Long Form') {
			return RichTextLong
		} else if (pageType === 'Squeeze') {
			return RichTextSqueeze
		} else {
			return null
		}
	} else if (type === 'ContentfulSectionProfiles') {
		if (pageType === 'Long Form') {
			return ProfilesLong
		} else if (pageType === 'Squeeze') {
			return ProfilesSqueeze
		} else {
			return null
		}
	} else if (type === 'ContentfulContact') {
		if (pageType === 'Long Form') {
			return ContactInfoLong
		} else if (pageType === 'Squeeze') {
			return ContactInfoSqueeze
		} else {
			return null
		}
	} else if (type === 'ContentfulLink') {
		if (pageType === 'Long Form') {
			return LinkCtaLong
		} else if (pageType === 'Squeeze') {
			return LinkCtaSqueeze
		} else {
			return null
		}
	} else {
		return null
	}
}

const DynamicSection = ({ data, pageType, ...props }) => {
	const Section = parseSectionType(data, pageType)

	return Section ? <Section data={data} pageType={pageType} {...props} /> : null
}
DynamicSection.propTypes = {
	pageType: PropTypes.string,
	data: PropTypes.shape({
		internal: PropTypes.shape({
			type: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
}
export default DynamicSection
