import React from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'
import { YT, H1, Vimeo } from '../../../UI'

const Section = styled.section`
	background: ${props => props.bgColor};
	padding: 22px ${props => props.theme.sizes.gutter.mobile.px};
	${props => props.theme.media.sdesk} {
		padding: 22px ${props => props.theme.sizes.gutter.sdesk.px};
	}
	h1 {
		font-size: 2.25em;
		letter-spacing: -0.54px;
		text-align: center;
		font-weight: 300;
		margin: 50px 0;
	}
	.video-wrap {
		${props => props.theme.media.sdesk} {
			max-width: 60vw;
			margin: 0 auto;
		}
	}
	.youtube > div {
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 56.25%;
		overflow: hidden;
		margin-bottom: 50px;
	}

	.youtube > div iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
`
const parseBg = (color, colors) =>
	color === 'White'
		? colors.white.val
		: color === 'Tan'
		? colors.orange.light('5').desat('4').val
		: colors.white.val // this is the default

const VideoSqueeze = ({ data, className }) => {
	const { ytLink, vimeoId, title, bgColor } = data
	const { colors } = useTheme()
	const parsedBg = parseBg(bgColor, colors)
	const isWhite = bgColor === 'White'
	return (
		<Section
			isWhite={isWhite}
			bgColor={parsedBg}
			className={`video-section${className ? ` ${className}` : ''}`}
		>
			<H1>{title}</H1>
			<div className='video-wrap'>
				{vimeoId ? <Vimeo vimeoId={vimeoId} /> : <YT url={ytLink} />}
			</div>
		</Section>
	)
}
VideoSqueeze.propTypes = {
	data: PropTypes.shape({
		ytLink: PropTypes.string,
	}),
}
export default VideoSqueeze
