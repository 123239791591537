import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Accordion, AccordionProvider, H3, Icon, RT } from '../../../UI'

const Section = styled.section`
	background: #e6ddd6;

	.arrow-wrap {
		width: 100%;
		text-align: center;
		overflow: hidden;
		height: 50px;
		${props => props.theme.media.sdesk} {
			height: 100px;
		}
		.arrow {
			width: 50px;
			height: 50px;
			color: ${props => props.theme.colors.white.val};
			opacity: 0.5;
			margin: -40px auto 0 auto;
			transform: rotate(90deg);
			${props => props.theme.media.sdesk} {
				width: 100px;
				height: 100px;
				//margin: -70px auto 0 -13px;
				margin: -70px auto 0 auto;
			}
		}
	}

	.inner {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		${props => props.theme.media.sdesk} {
			flex-direction: row;
		}
		padding: 0;
		${props => props.theme.media.sdesk} {
			padding: 22px ${props => props.theme.sizes.gutter.sdesk.px} 100px
				${props => props.theme.sizes.gutter.sdesk.px};
		}
	}

	.accordion-wrap {
		padding: 0 40px 40px 40px;
		max-width: 300px;
		${props => props.theme.media.sdesk} {
			max-width: 400px;
		}

		h3 {
			font-size: 1.5rem;
			font-weight: 200;
			text-align: center;
			margin-bottom: 40px;
			${props => props.theme.media.sdesk} {
				font-size: 2rem;
			}
		}
		.icon {
			position: absolute;
			right: 0;
			top: 0;
			min-width: 400px;
			height: 100%;
			overflow: hidden;
			${props => props.theme.media.sdesk} {
				min-width: initial;
				width: 50%;
				right: auto;
				left: 66%;
			}
			svg {
				width: 100%;
				height: auto;
				position: absolute;
				bottom: 0;
				fill: #a4c8d6;
				opacity: 0.07;
				${props => props.theme.media.sdesk} {
					position: relative;
					height: 100%;
					width: auto;
					max-height: 300px;
				}
			}
		}

		.accordions {
			button {
				font-size: 1rem;
				letter-spacing: 0.5px;
				font-weight: 500;
				text-transform: uppercase;
				${props => props.theme.media.sdesk} {
					font-size: 1.3rem;
				}
				color: ${props => props.theme.colors.slate.val};
				.toggle {
					span {
						height: 3px;
					}
				}
			}
			.accordion-content {
				padding: 0 0 0 36px;
				.rt {
					padding-bottom: 4px;
				}
				a {
					border-color: inherit;
					&:hover {
						border-color: transparent;
					}
				}
				p {
					font-size: 1.5em;
					color: ${props => props.theme.colors.slate.light('1').val};
					${props => props.theme.media.sdesk} {
						font-size: 1rem;
						line-height: 1.2rem;
					}
				}
			}
		}
	}
`

const AccordionSqueeze = ({ data, className }) => {
	const { icon, items, heading } = data

	return (
		<Section className={`cta-accordion-section${className ? ` ${className}` : ''}`}>
			<div className={'arrow-wrap'}>
				<Icon type='arrow' />
			</div>
			<div className={'inner flex'}>
				<div className={`accordion-wrap`}>
					{icon && <Icon type={icon.type} />}
					<H3>{heading}</H3>
					<AccordionProvider>
						<div className={`accordions`}>
							{items.map(({ id, title, content, richText }) => (
								<Accordion key={id} title={title} showPlus id={id}>
									{content ? <RT className='text'>{content.text}</RT> : null}
									{richText ? <RT content={richText} /> : null}
								</Accordion>
							))}
						</div>
					</AccordionProvider>
				</div>
			</div>
		</Section>
	)
}
AccordionSqueeze.propTypes = {
	data: PropTypes.shape({
		heading: PropTypes.string.isRequired,
	}),
}
export default AccordionSqueeze
