import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import ContactInfo from '../../ContactInfo'

const Section = styled.section`
	background: ${props => props.theme.colors.skyBlue.light(4).desat().val};
	padding: 22px ${props => props.theme.sizes.gutter.mobile.px} 40px
		${props => props.theme.sizes.gutter.mobile.px};
	padding-top: 0;
	${props => props.theme.media.sdesk} {
		padding: 22px ${props => props.theme.sizes.gutter.sdesk.px} 100px
			${props => props.theme.sizes.gutter.sdesk.px};
	}
`

const ContactInfoSqueeze = ({ data, className }) => {
	return (
		<Section className={`cta-contact-section${className ? ` ${className}` : ''}`}>
			<ContactInfo contactInfo={data} />
		</Section>
	)
}
ContactInfoSqueeze.propTypes = {
	data: PropTypes.shape({
		city: PropTypes.string,
	}),
}
export default ContactInfoSqueeze
