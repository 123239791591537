import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import styled, { useTheme } from 'styled-components'
import { H2 } from '../../../UI'
import SectionHeading from '../Section.Heading'

const Section = styled.section`
	background: ${props => props.theme.colors.skyBlue.light('1').desat('5').val};
	text-align: center;
	.inner {
		padding: 22px ${props => props.theme.sizes.gutter.mobile.px};
		${props => props.theme.media.sdesk} {
			padding: 22px ${props => props.theme.sizes.gutter.sdesk.px};
		}
	}
	h2 {
		color: ${props => props.theme.colors.white.val};
		font-size: 1.7rem;
		letter-spacing: -0.54px;
		padding-bottom: 22px;
		${props => props.theme.media.sdesk} {
			font-size: 2.75rem;
			font-weight: 700;
		}
	}
	.logos-wrap {
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		${props => props.theme.media.sdesk} {
			flex-direction: row;
		}
		img {
			max-width: 180px;
			margin: 15px 0;
			${props => props.theme.media.sdesk} {
				margin: 0;
			}
		}
	}
`

const parseHeaderBgColor = (color, colors) =>
	color === 'Dark Slate'
		? colors.slate.val
		: color === 'Light Slate'
		? colors.heroSlate.val
		: colors.slate.val // this is the default

const LogosLong = ({ data, className }) => {
	const { sectionHeading, headerBgColor, heading, logos } = data
	const { colors } = useTheme()
	const parsedHeaderBgColor = parseHeaderBgColor(headerBgColor, colors)

	return (
		<Section className={`logos-section${className ? ` ${className}` : ''}`}>
			<SectionHeading headerBgColor={parsedHeaderBgColor}>{sectionHeading}</SectionHeading>
			<div className={'inner'}>
				<H2>{heading}</H2>
				<div className={`logos-wrap flex`}>
					{logos.map(logo => (
						<img
							key={logo.id}
							src={get(logo, 'image.fluid.src')}
							alt={get(logo, 'image.title', '')}
						/>
					))}
				</div>
			</div>
		</Section>
	)
}
LogosLong.propTypes = {
	data: PropTypes.shape({
		heading: PropTypes.string.isRequired,
	}),
}
export default LogosLong
