import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'
import get from 'lodash/get'

import { Link, H6, P, RatingStars } from '../UI'
import { parsePageURI, truncText } from '../../utils'

const Card = styled(Link)`
	color: ${props => props.theme.colors.black.val};
	margin-bottom: 10px;
	width: 100%;
	text-decoration: none;
	& > .flex-desk {
		justify-content: stretch;
		margin-top: 40px;
		${props => props.theme.media.tablet} {
			margin-top: 0;
		}
	}
	.pic,
	.bare-pic {
		width: 150px;
		max-width: 100%;
		height: 175px;
		border: solid 1px ${props => props.theme.colors.grey.val};
		${props => props.theme.media.tablet} {
			max-width: 150px;
		}
		${props => props.theme.media.sdesk} {
			min-width: 150px;
			min-height: 175px;
			max-width: 150px;
			max-height: 175px;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.pic {
		margin: 0 auto;
	}
	.text {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		text-align: left;
		font-size: 1rem;
		${props => props.theme.media.sdesk} {
			flex-direction: column;
			padding-left: 1em;
			align-items: flex-start;
		}
		.rating-stars {
			justify-content: flex-start;
			order: 2;
			${props => props.theme.media.sdesk} {
				order: 1;
			}
			.star {
				height: 20px;
				width: 20px;
				margin: 5px 10px 5px 0;
				${props => props.theme.media.sdesk} {
					height: 30px;
					width: 30px;
					margin: 10px 10px 10px 0;
				}
			}
		}
		h6 {
			font-weight: 400;
			font-size: 1rem;
			order: 1;
			${props => props.theme.media.sdesk} {
				font-size: 1.2em;
				order: 2;
			}
		}
		.title {
			order: 0;
			${props => props.theme.media.sdesk} {
				order: 3;
			}
		}
		p {
			text-align: center;
			order: -1;
			${props => props.theme.media.sdesk} {
				text-align: left;
				order: 4;
			}
		}
	}
`

const ProfilesCard = ({ profile }) => {
	const { name, rating, pic, picUrl, orgTitle, bio } = profile
	const bioContent = get(bio, 'content')
	const bioValue = bioContent && bioContent[0]
	const bioText = get(bioValue, 'text')
	const bioRaw = bioText && bioText[0]
	const bioVal = get(bioRaw, 'raw')
	return (
		<Card noStyles className='profile' to={parsePageURI(profile)}>
			<div className='flex-desk'>
				{pic ? (
					<Img className='pic' fluid={pic.fluid} alt={get(pic, 'title', name)} />
				) : picUrl ? (
					<div className='bare-pic'>
						<img src={picUrl} alt={name} />
					</div>
				) : null}
				<div className='text'>
					<RatingStars rating={rating} />
					<H6>{name}</H6>
					{orgTitle && <span className='title'>{orgTitle}</span>}
					{bioVal ? <P>{truncText(bioVal, { length: 2000 })}</P> : null}
				</div>
			</div>
		</Card>
	)
}
ProfilesCard.propTypes = {
	profile: PropTypes.shape({
		name: PropTypes.string,
		rating: PropTypes.number,
	}),
}
export default ProfilesCard
