import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import styled, { useTheme } from 'styled-components'
import { H4, Link, RT, Icon } from '../../../UI'

const Section = styled.section`
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	position: relative;
	background: ${props => props.bgColor};
	color: ${props => (props.isDark ? props.theme.colors.white.val : 'inherit')};
	padding: 22px ${props => props.theme.sizes.gutter.mobile.px};
	padding-bottom: 52px;
	${props => props.theme.media.sdesk} {
		padding: 82px ${props => props.theme.sizes.gutter.sdesk.px};
		flex-direction: row;
	}
`

const Col = styled.div`
	flex: 1;
	width: 100%;
	height: auto;
	align-self: flex-start;
	text-align: center;
	${props => props.theme.media.sdesk} {
		text-align: left;
	}
	h4 {
		display: ${props => (props.otherColIsPic ? 'none' : 'block')};
		font-size: 1.7rem;
		font-weight: 900;
		margin-bottom: 2rem;
		${props => props.theme.media.sdesk} {
			display: block;
		}
	}
	.copy {
		font-size: 1rem;
		line-height: 1.2rem;
		${props => props.theme.media.sdesk} {
			font-size: 1.3rem;
		}
	}
	.icon-wrap {
		margin-bottom: 1em;
		position: relative;
		display: flex;
		width: 100%;
		justify-content: center;
		${props => props.theme.media.sdesk} {
			display: block;
			min-width: 10vw;
			margin-right: 1em;
		}
		.icon {
			display: block;
			height: auto;
			width: 100%;
			max-width: 120px;
			position: relative;
			${props => props.theme.media.tablet} {
				max-width: 200px;
			}
			${props => props.theme.media.sdesk} {
				margin-right: 40px;
			}
			svg {
				width: 100%;
				height: 100%;
				display: block;
			}
		}
	}
	.cta-wrap {
		margin-top: 40px;
		${props => props.theme.media.sdesk} {
			.cta {
			}
		}
	}
	&.col-left {
		margin: 0 0 20px 0;
		${props => props.theme.media.sdesk} {
			margin: 0 20px 0 0;
		}
		h4 {
			${props => props.theme.media.sdesk} {
				font-size: 2rem;
			}
		}
		.copy {
			line-height: 1.5rem;
		}
	}
	&.col-right {
		margin: 20px 0 0 0;
		${props => props.theme.media.sdesk} {
			margin: 0 0 0 20px;
		}
		h4 {
			${props => props.theme.media.sdesk} {
				font-size: 1.7rem;
				font-weight: 200;
			}
		}
		.copy {
			line-height: 1.5rem;
			font-weight: 700;
			${props => props.theme.media.sdesk} {
				font-size: 1.2rem;
				line-height: 1.7rem;
			}
		}
	}
	&.dark {
		.copy {
			color: ${props => props.theme.colors.white.val};
		}
	}
`

const isDarkBg = bgColor => bgColor === 'Dark Blue' || bgColor === 'Slate'

const parseBGColor = (color, colors) =>
	color === 'White'
		? colors.white.val
		: color === 'Salmon'
		? '#E6C5AE'
		: color === 'Light Blue'
		? colors.skyBlue.light('4').val
		: color === 'Dark Blue'
		? colors.slate.light('4').val
		: color === 'Slate'
		? colors.slate.val
		: colors.white.val // this is the default

const parseIconColor = (color, colors) => {
	switch (color) {
		case 'Salmon':
			return colors.orange.desat(3).desat(2).val
		default:
			return colors.skyBlue.desat(2).light(1).val
	}
}

const Column = ({ colData, type, isDark }) => {
	const { heading, cta, content, icon } = colData || {}

	return (
		<Col className={`col col-${type} ${isDark ? ' dark' : ''}`}>
			<div className={`col-content`}>
				{heading && <H4>{heading}</H4>}
				<div className='flex-desk'>
					{icon ? (
						<div className={`icon-wrap`}>
							<Icon type={icon.type} />
						</div>
					) : null}
					<div className='text'>
						{get(content, 'text') && <RT className='copy'>{content.text}</RT>}

						{get(cta, 'link') && (
							<div className='cta-wrap'>
								<Link isCta isWhite={isDark} isDark={!isDark} to={cta.link}>
									{cta.title || cta.link}
								</Link>
							</div>
						)}
					</div>
				</div>
			</div>
		</Col>
	)
}

const ColumnsCTASection = ({ data, className }) => {
	const { bgColor, leftColumn, rightColumn } = data
	const { colors } = useTheme()
	const parsedBgColor = parseBGColor(bgColor, colors)
	const iconColor = parseIconColor(bgColor, colors)

	const isDark = isDarkBg(bgColor)

	return (
		<Section
			isDark={isDark}
			className={`cta-col-section${className ? ` ${className}` : ''}`}
			bgColor={parsedBgColor}
			iconColor={iconColor}
		>
			<Column colData={leftColumn} type='left' isDark={isDark} />
			<Column colData={rightColumn} type='right' isDark={isDark} />
		</Section>
	)
}
ColumnsCTASection.propTypes = {
	data: PropTypes.shape({
		bgColor: PropTypes.string,
	}),
}
export default ColumnsCTASection
