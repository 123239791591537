import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import Page from '../components/common/Page.layout'
import Section from '../components/common/Section'
import Sections from '../components/common/Section/Sections.wrap'
import SEO from '../components/common/SEO'

const Wrap = styled.div`
	.page-wrapper {
		min-height: calc(100vh - 178px);
	}
	.breadcrumbs-section {
		background: ${props => props.theme.colors.skyBlue.light(4).desat().val};
		padding: 10px ${props => props.theme.sizes.gutter.mobile.px};
		padding-bottom: 0;
		${props => props.theme.media.sdesk} {
			padding: 22px ${props => props.theme.sizes.gutter.sdesk.px};
			padding-bottom: 0;
		}
	}
`

const PageTpl = ({ pageContext, data }) => {
	const { breadcrumbs } = pageContext
	const { page } = data

	const { sections, displayBreadcrumbs = true, pageType } = page

	const isSingleSection = sections.length === 1

	return (
		<Wrap>
			<SEO pageData={page} />
			<Page pageContext={pageContext} pageData={page}>
				<div className={`page-wrapper`}>
					<Sections>
						{sections &&
							sections.map(section => (
								<Section
									pageType={pageType}
									key={section.id}
									data={{ isSingleSection, ...section }}
									breadcrumbs={displayBreadcrumbs ? breadcrumbs : null}
								/>
							))}
					</Sections>
				</div>
			</Page>
		</Wrap>
	)
}
export const pageQuery = graphql`
	query pageTplQuery($pageId: String) {
		page: contentfulPage(contentful_id: { eq: $pageId }) {
			id: contentful_id
			pageTitle
			pageType
			slug
			breadcrumb
			group1: pagegroup {
				id: contentful_id
				title: breadcrumb
				slug
			}
			group2: pagegroup2 {
				id: contentful_id
				title: breadcrumb
				slug
				group: pagegroup {
					id: contentful_id
					title: breadcrumb
					slug
				}
			}
			sections {
				... on ContentfulFourCardSection {
					internal {
						type
					}
					id: contentful_id
					cards {
						id: contentful_id
						text {
							text
						}
						title
					}
					sectionHeading
					headline
					headerBgColor
					link {
						link
						title
					}
				}
				... on ContentfulRichText {
					internal {
						type
					}
					id: contentful_id
					sectionHeading
					headerBgColor
					content {
						json
					}
				}
				... on ContentfulSectionCampuses {
					internal {
						type
					}
					id: contentful_id
					headline
					subHeadline
					sectionHeading
					headerBgColor
					campuses {
						id: contentful_id
						slug
						name: breadcrumb
						contactInfo {
							city
							fax
							phone
							state
							street
							zip
						}
						bannerPic {
							title
							fluid(maxWidth: 600) {
								...GatsbyContentfulFluid
							}
						}
						content {
							json
						}
						form {
							... on ContentfulHubspotOrPardotForm {
								portalId
								formId
								title
							}
						}
					}
				}
				... on ContentfulContact {
					internal {
						type
					}
					id: contentful_id
					title
					city
					email
					phone
					state
					street
					zip
					sectionHeading
					headerBgColor
				}
				... on ContentfulSectionCta {
					internal {
						type
					}
					id: contentful_id
					section {
						... on ContentfulCtaSectionGen {
							internal {
								type
							}
							ctaType
							bgFormat
							richText {
								json
							}
							cta {
								link
								title
							}
							heading
							icon {
								type
							}
							pic {
								title
								fluid {
									src
								}
							}
						}
						... on ContentfulCtaSectionList {
							internal {
								type
							}
							caption
							icon {
								type
							}
							description {
								text: description
							}
							cta {
								link
								title
							}
							accordion {
								id: contentful_id
								heading
								items {
									id: contentful_id
									title
									content: text {
										text
									}
									richText {
										json
									}
								}
							}
						}
						... on ContentfulCtaSectionColumns {
							internal {
								type
							}
							bgColor
							leftColumn {
								cta {
									link
									title
								}
								heading
								icon {
									type
								}
								content: contentShort {
									text: contentShort
								}
							}
							rightColumn {
								cta {
									link
									title
								}
								heading
								icon {
									type
								}
								content: contentShort {
									text: contentShort
								}
							}
						}
					}
				}
				... on ContentfulSectionHero {
					internal {
						type
					}
					id: contentful_id
					bgColor
					cta {
						link
						title
					}
					rightColConfig
					altText {
						title
						content: text {
							text
						}
						richText {
							json
						}
					}
					headingBottom
					headingTop
					logo {
						image {
							fluid {
								src
							}
						}
						link
					}
					heroPic {
						title
						description
						fluid(maxHeight: 1500) {
							...GatsbyContentfulFluid
						}
					}
					text {
						text
					}
					form: contactForm {
						... on ContentfulHubspotOrPardotForm {
							title
							portalId
							formId
							bgColor
						}
					}
				}
				... on ContentfulSectionHubspot {
					internal {
						type
					}
					id: contentful_id
					sectionHeading
					headerBgColor
					form {
						... on ContentfulHubspotOrPardotForm {
							title
							formId
							portalId
							scrollToId
							marketingPlatform
						}
					}
					contactInfo {
						title
						street
						city
						state
						zip
						phone
					}
				}
				... on ContentfulSectionIcons {
					internal {
						type
					}
					id: contentful_id
					sectionHeading
					heading
					headerBgColor
					icons {
						id: contentful_id
						title
						type
						link
						text
					}
				}
				... on ContentfulSectionLogos {
					internal {
						type
					}
					id: contentful_id
					sectionHeading
					heading
					headerBgColor

					logos {
						id: contentful_id
						image {
							title
							fluid {
								src
							}
						}
					}
				}
				... on ContentfulSectionRating {
					internal {
						type
					}
					id: contentful_id
					cta {
						link
						title
					}
					bg
					heading
					sectionHeading
					headerBgColor
					rating
					testimonial {
						cite
						citeTitle
						content {
							text: content
						}
					}
				}
				... on ContentfulSectionVideo {
					internal {
						type
					}
					id: contentful_id
					title
					sectionHeading
					headerBgColor
					ytLink
					vimeoId
					bgColor
					rating
					testimonial: quote {
						cite
						citeTitle
						content {
							text: content
						}
					}
				}
				... on ContentfulAccordion {
					id: contentful_id
					internal {
						type
					}
					icon {
						type
					}
					heading
					sectionHeading
					headerBgColor
					displayOptions
					text: content {
						json
					}
					items {
						id: contentful_id
						title
						content: text {
							text
						}
						richText {
							json
						}
					}
				}
				... on ContentfulSectionProfiles {
					id: contentful_id
					internal {
						type
					}
					heading
					sectionHeading
					headerBgColor
					profiles {
						id: contentful_id
						rating
						bio {
							json
							content {
								text: content {
									raw: value
								}
							}
						}
						name
						slug
						orgTitle
						pic {
							title
							fluid(maxWidth: 300) {
								...GatsbyContentfulFluid
							}
						}
						group2: pagegroup2 {
							id: contentful_id
							slug
							title
							group: pagegroup {
								id: contentful_id
								slug
								title
							}
						}
						group1: pagegroup {
							id: contentful_id
							slug
							title
						}
					}
				}
			}
		}
	}
`
PageTpl.propTypes = {
	pageContext: PropTypes.shape({
		pageId: PropTypes.string.isRequired,
	}).isRequired,
}
export default PageTpl
