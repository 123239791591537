import React from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'
import { YT, H1, Vimeo, Rating } from '../../../UI'
import SectionHeading from '../Section.Heading'

const Section = styled.section`
	background: ${props => props.bgColor};
	.inner {
		padding: 22px ${props => props.theme.sizes.gutter.mobile.px};
		${props => props.theme.media.sdesk} {
			padding: 22px ${props => props.theme.sizes.gutter.sdesk.px};
		}
	}
	h1 {
		font-size: 2.25em;
		letter-spacing: -0.54px;
		text-align: center;
		font-weight: 300;
		margin: 50px 0;
	}
	.video-wrap {
		${props => props.theme.media.sdesk} {
			max-width: 60vw;
			margin: 0 auto;
		}
	}
	.youtube > div {
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 56.25%;
		overflow: hidden;
		margin-bottom: 50px;
	}

	.youtube > div iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
`
const parseBg = (color, colors) =>
	color === 'White'
		? colors.white.val
		: color === 'Tan'
		? colors.orange.light('5').desat('4').val
		: colors.white.val // this is the default

const parseHeaderBgColor = (color, colors) =>
	color === 'Dark Slate'
		? colors.slate.val
		: color === 'Light Slate'
		? colors.heroSlate.val
		: colors.slate.val // this is the default

const VideoLong = ({ data, className }) => {
	const {
		sectionHeading,
		headerBgColor,
		ytLink,
		vimeoId,
		title,
		bgColor,
		rating,
		testimonial,
	} = data
	const { colors } = useTheme()
	const parsedBg = parseBg(bgColor, colors)
	const parsedHeaderBgColor = parseHeaderBgColor(headerBgColor, colors)
	const isWhite = bgColor === 'White'
	return (
		<Section
			isWhite={isWhite}
			bgColor={parsedBg}
			className={`video-section${className ? ` ${className}` : ''}`}
		>
			<SectionHeading headerBgColor={parsedHeaderBgColor}>{sectionHeading}</SectionHeading>
			<div className={'inner'}>
				<H1>{title}</H1>
				<div className='video-wrap'>
					{vimeoId ? <Vimeo vimeoId={vimeoId} /> : <YT url={ytLink} />}
				</div>
				{rating ? <Rating rating={rating} testimonial={testimonial} /> : null}
			</div>
		</Section>
	)
}
VideoLong.propTypes = {
	data: PropTypes.shape({
		ytLink: PropTypes.string,
	}),
}
export default VideoLong
