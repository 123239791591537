import get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { BGBlocks, H2, Icon, Link, RT } from '../../../UI'

const Section = styled.section`
	background: ${props => props.bgColor};
	&>.flex-desk {
		align-items: stretch;
	}
	.bg-blocks {
		z-index: 0;
		display: none;
		${props => props.theme.media.sdesk} {
			display: block;
			fill: ${props => props.iconColor};
			opacity: 0.4;
		}
	}
	h2 {
		font-weight: 700;
		font-size: 1.1rem;
		${props => props.theme.media.sdesk} {
			font-size: 25px;
			font-weight: 800;
		}
	}
	.pic {
		object-fit: cover;
	}
	.icon-wrap {
		padding: 3em;
	}
	.icon {
		width: 100%;
		height: auto;
		max-width: 175px;
		margin: 0 auto;
		display: block;
		color: ${props => props.iconColor};

		svg {
			height: auto;
			width: 100%;
			vertical-align: bottom;
		}
	}
	.text {
		padding: 0 ${props => props.theme.sizes.gutter.mobile.num}px;
		padding-top: 1.3em;
		padding-bottom: 2em;
		${props => props.theme.media.sdesk} {
			padding-right: ${props => props.theme.sizes.gutter.sdesk.val};
			padding-top: 40px;
			flex-direction: column;
			justify-content: center;
			flex-grow: 1;
		}
		h2 {
			${props => props.theme.media.sdesk} {
				display: block;
				z-index: 1;
			}
		}
		.rt {
			padding-top: 1rem;
		}
		.cta-box {
			display: flex;
			justify-content: center;
			margin-top: 1em;
			z-index: 1;
			${props => props.theme.media.sdesk} {
				display: block;
				margin-top: 2em;
			}
		}
	}
	/* &.__wrap-pic {
		${props => props.theme.media.mobile} {
			padding: ${props => props.theme.sizes.gutter.mobile.num}px;
		}
		.pic-heading {
			display: none;
		}
		.icon {
			${props => props.theme.media.mobile} {
				width: 25%;
				float: left;
				margin: 0 10px;
				min-width: 0;
				padding: 0;
				svg {
					width: 100%;
					height: auto;
				}
			}
		}
		.text {
			${props => props.theme.media.mobile} {
				display: inline;
				padding: 0;
				h2 {
					display: block;
				}
			}
		}
	} */
`

const parseBgColor = (color, colors) => {
	switch (color) {
		case 'Salmon':
			return colors.orange.desat().light().val
		default:
			return colors.white.val
	}
}

const parseIconColor = (color, colors) => {
	switch (color) {
		case 'Salmon':
			return colors.orange.desat(3).desat(2).val
		default:
			return colors.skyBlue.desat(2).light(1).val
	}
}

const GenericCTASection = ({ data, className = '' }) => {
	const { bgFormat, richText, cta, cta2, cta3, cta4, heading, icon, pic } = data

	const { colors } = useTheme()
	const bgColor = parseBgColor(bgFormat, colors)
	const iconColor = parseIconColor(bgFormat, colors)

	return (
		<Section
			bgColor={bgColor}
			iconColor={iconColor}
			className={`use-bootstrap cta-gen-section${className ? ` ${className}` : ''}`}
		>
			<div className='row d-flex'>
				{pic && <img src={pic.fluid.src} alt={get(pic, 'title', heading)} className='col-12 col-lg-6 pic' />}
				{icon && (
					<div className='col-12 col-md-3 icon-wrap'>
						<Icon type={icon.type} />
					</div>
				)}
				<div className={ icon ? 'col-12 col-md-6 text' : 'col-12 col-lg-6 text' }> 
					{bgFormat === 'Blocks' && <BGBlocks />}
					<H2>{heading}</H2>
					{richText && <RT content={richText} />}
					{cta && (
						<div className='cta-box'>
							<Link to={cta.link} isCta isDark>
								{cta.title}
							</Link>
						</div>
					)}
					{cta2 && (
						<div className='cta-box'>
							<Link to={cta2.link} isCta isDark>
								{cta2.title}
							</Link>
						</div>
					)}
					{cta3 && (
						<div className='cta-box'>
							<Link to={cta3.link} isCta isDark>
								{cta3.title}
							</Link>
						</div>
					)}
					{cta4 && (
						<div className='cta-box'>
							<Link to={cta4.link} isCta isDark>
								{cta4.title}
							</Link>
						</div>
					)}
				</div>
			</div>
		</Section>
	)
}
GenericCTASection.propTypes = {
	data: PropTypes.shape({
		bgFormat: PropTypes.string,
		picFormat: PropTypes.string,
		heading: PropTypes.string.isRequired,
		richText: PropTypes.shape({
			json: PropTypes.shape({}),
		}),
		icon: PropTypes.shape({
			type: PropTypes.string.isRequired,
		}),
		pic: PropTypes.shape({
			fluid: PropTypes.shape({
				src: PropTypes.string,
			}),
		}),
	}),
}
export default GenericCTASection
