import React from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'
import { H2, Icon, Link } from '../../../UI'
import SectionHeading from '../Section.Heading'

const Section = styled.section`
	/* background: ${props => props.theme.colors.dkOrange.light(25).desat(4).val}; */
  //background: #CCB0A4;
  background: #E5DDD6;
	text-align: center;
  .section-content{
    padding: 22px ${props => props.theme.sizes.gutter.mobile.px};
    padding-bottom: 44px;
    ${props => props.theme.media.sdesk} {
		padding: 22px ${props => props.theme.sizes.gutter.sdesk.px};
	}

  }
	h2 {
		color: ${props => props.theme.colors.text.val};
		font-size: 2.4rem;
		letter-spacing: -3.2px;
		padding-bottom: 22px;
		font-weight: 100;
		text-align: left;
		${props => props.theme.media.sdesk} {
			font-size: 4rem;
		}
	}
	.icons-wrap {
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		${props => props.theme.media.sdesk} {
			flex-direction: row;
		}
		.icon {
      flex:1;
      width:100%;
      padding:0;
      ${props => props.theme.media.sdesk} {
        padding:0 20px;
      }
			i {
				max-width: 90px;
				width: 100%;
				height: 90px;
				margin: 15px 0;
				${props => props.theme.media.sdesk} {
					margin: 0;
          max-width: 165px;
          height: 165px;
				}
				svg {
          max-width:145px;
          max-height:154px;
          margin:0 auto;
					width: 100%;
					/* color: ${props => props.theme.colors.dkOrange.desat('1').val}; */
          //color: #AA7D66;
          color: ${props => props.theme.colors.text.val};
          ${props => props.theme.media.sdesk} {
            max-width:165px;
            max-height:165px;
          }
				}
			}
      h3{
      color: ${props => props.theme.colors.text.val};
			font-size: 1.6rem;
			text-transform: uppercase;
			font-weight:600;
      margin:20px 0;
    }
		p {
			color: ${props => props.theme.colors.text.val};
			font-size: 1.3rem;
      line-height: 1.8rem;
			letter-spacing: -1.28px;
      margin-bottom:20px;
      ${props => props.theme.media.sdesk} {
        font-size: 1.6rem;
        padding:0 80px;
      }
		}
		}
	}
`

const IconCard = ({ icon }) => (
	<div className={`icon`}>
		<Icon type={icon.type} />
		<h3>{icon.title}</h3>
		<p>{icon.text}</p>
	</div>
)

const parseHeaderBgColor = (color, colors) =>
	color === 'Dark Slate'
		? colors.slate.val
		: color === 'Light Slate'
		? colors.heroSlate.val
		: colors.slate.val // this is the default

const IconsLong = ({ data, className }) => {
	const { sectionHeading, headerBgColor, heading, icons } = data
	const { colors } = useTheme()
	const parsedHeaderBgColor = parseHeaderBgColor(headerBgColor, colors)

	return (
		<Section className={`icons-section${className ? ` ${className}` : ''}`}>
			<SectionHeading headerBgColor={parsedHeaderBgColor}>{sectionHeading}</SectionHeading>
			<div className={`section-content`}>
				<H2>{heading}</H2>
				<div className={`icons-wrap flex`}>
					{icons.map(({ link, id, ...icon }) => (
						<React.Fragment key={id}>
							{link ? (
								<Link to={link} noStyles>
									<IconCard icon={icon} />
								</Link>
							) : (
								<IconCard icon={icon} />
							)}
						</React.Fragment>
					))}
				</div>
			</div>
		</Section>
	)
}
IconsLong.propTypes = {
	data: PropTypes.shape({
		heading: PropTypes.string,
	}),
}
export default IconsLong
