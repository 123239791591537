import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Link, Small } from '../../UI'

const Wrap = styled.ol`
	padding: 1em;
	padding-left: 0;
	color: ${props => props.theme.colors.white.val};
	flex-wrap: wrap;
	font-weight: 300;
	list-style: none;
	${props => props.theme.media.sdesk} {
		white-space: nowrap;
	}
	.crumb {
		display: none;
		.caret {
			display: none;
			${props => props.theme.media.tablet} {
				display: inline-block;
			}
		}
		${props => props.theme.media.tablet} {
			display: inline-block;
		}
		&:first-child {
			display: inline-block;
			.link {
				display: none;
				${props => props.theme.media.tablet} {
					margin-left: 0;
					display: inline-block;
				}
			}
			.caret {
				transform: scaleX(-1);
				display: inline-block;
				margin-right: 0.5em;
				${props => props.theme.media.tablet} {
					transform: none;
				}
			}
		}
		&:nth-last-child(2) {
			display: inline-block;
		}
		&:last-child {
			.caret {
				display: none;
			}
		}
		&.home-crumb {
			.home-caret {
				display: none;
			}
			&.__show-mobile {
				.link {
					display: inline-block;
				}
				.home-caret {
					display: inline-block;
					${props => props.theme.media.tablet} {
						display: none;
					}
				}
				.caret {
					display: none;
					${props => props.theme.media.tablet} {
						display: inline-block;
					}
				}
			}
		}
	}
	.link,
	.last-crumb {
		white-space: nowrap;
	}
	.link {
		color: inherit;
		margin: 0 0.5em;
		border-bottom: solid 1px ${props => props.theme.colors.white.val};
		&:first-child {
			margin-left: 0;
		}
		&:hover {
			border-bottom: none;
			border-bottom: solid 1px transparent;
			color: ${props => props.theme.colors.white.val};
		}
	}
	.last-crumb {
		small {
			margin-left: 0.5em;
		}
	}
	&.light {
		color: ${props => props.theme.colors.black.val};
		.link {
			border-bottom: solid 1px ${props => props.theme.colors.black.val};
			&:hover {
				color: ${props => props.theme.colors.black.val};
				border-bottom: none;
				border-bottom: solid 1px transparent;
			}
		}
	}
	${props => props.theme.media.sdesk} {
	}
`

const Crumb = ({ to, children, isLast }) =>
	isLast ? (
		<li className='crumb'>
			<Link to={to} noStyles>
				<Small>{children}</Small>
			</Link>
			<span className='caret'>{`>`}</span>
		</li>
	) : (
		<li className='crumb last-crumb'>
			<span>
				<Small>{children}</Small>
			</span>
		</li>
	)

const Breadcrumbs = ({ breadcrumbs, isLight }) => {
	return (
		<Wrap className={`breadcrumbs flex${isLight ? ' light' : ''}`}>
			<li className={`crumb home-crumb${breadcrumbs.length === 1 ? ' __show-mobile' : ''}`}>
				<span className='home-caret'>{`<`}</span>
				<Link noStyles to='/'>
					<Small>Home</Small>
				</Link>
				<span className='caret'>{`>`}</span>
			</li>
			{breadcrumbs.map(({ slug, id, title }, idx) => (
				<Crumb
					key={id || idx}
					isLast={idx + 1 < breadcrumbs.length}
					to={`/${breadcrumbs
						.slice(0, idx + 1)
						.map(({ slug }) => slug)
						.join('/')}`}
				>
					{title || slug}
				</Crumb>
			))}
		</Wrap>
	)
}
Breadcrumbs.propTypes = {
	breadcrumbs: PropTypes.arrayOf(
		PropTypes.shape({
			slug: PropTypes.string.isRequired,
			title: PropTypes.string,
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		})
	).isRequired,
}
export default Breadcrumbs
