import React from 'react'
import styled from 'styled-components'
import { H2, Link } from '../../../UI'

const Section = styled.section`
	background: ${props => props.theme.colors.slate.val};
	padding: 22px ${props => props.theme.sizes.gutter.mobile.px};
	text-align: center;
	${props => props.theme.media.sdesk} {
		padding: 22px ${props => props.theme.sizes.gutter.sdesk.px};
	}
	h2 {
		font-weight: 900;
		font-size: 1.3em;
		color: ${props => props.theme.colors.white.val};
		margin: 0 0 20px 10px;
		flex: 1;
		${props => props.theme.media.sdesk} {
			text-align: right;
			margin: 0;
			margin-right: 1em;
			font-size: 1.8rem;
			font-weight: 700;
		}
	}
	.cta-box {
		flex: 1;
		text-align: center;
		margin: 1em auto;
		${props => props.theme.media.sdesk} {
			text-align: left;
			margin-left: 1em;
		}
	}
`

const SimpleCTASection = ({ data, className }) => {
	const { heading, cta } = data

	return (
		<Section className={`cta-simple-section${className ? ` ${className}` : ''}`}>
			<div className='flex-desk center'>
				<H2>{heading}</H2>
				{cta && (
					<div className='cta-box'>
						<Link to={cta.link} isCta isWhite>
							{cta.title}
						</Link>
					</div>
				)}
			</div>
		</Section>
	)
}
SimpleCTASection.propTypes = {}
export default SimpleCTASection
