import useWindowSize from './useWindowSize'

/** Constants */

const CSS_VALUES = {
	padding: {
		mobile: '34px',
		desktop: '10vw',
	},
}

/** Hook */

/** Returns various suggested CSS values for the given screen size.
 * TODO: Potentially refactor more CSS properties into this hook. */
const useCalculatedCSSProperties = () => {
	const { isSmall } = useWindowSize()

	return isSmall
		? {
				padding: CSS_VALUES.padding.mobile,
		  }
		: {
				padding: CSS_VALUES.padding.desktop,
		  }
}

/** Exports */

export default useCalculatedCSSProperties
