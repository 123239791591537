import React from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'
import Card from '../../../Profiles/Profiles.card'
import { H2 } from '../../../UI'
import SectionHeading from '../Section.Heading'

const Section = styled.section`
	background: ${props => props.bgColor};
	h2 {
		font-size: 1.2rem;
		margin-bottom: 10px;
		${props => props.theme.media.sdesk} {
			font-size: 3rem;
			font-weight: 200;
			text-align: center;
			margin-bottom: 2.5rem;
		}
	}
	.text {
		justify-content: flex-start;
	}
	.profiles {
		padding: 22px ${props => props.theme.sizes.gutter.mobile.px};
		${props => props.theme.media.sdesk} {
			padding: 60px ${props => props.theme.sizes.gutter.sdesk.px};
		}
	}
	.profile {
		${props => props.theme.media.sdesk} {
			margin-bottom: 30px;
			display: block;
			&:last-child {
				margin-bottom: 0;
			}
		}
		.pic {
			margin-bottom: 10px;
			${props => props.theme.media.sdesk} {
				max-width: 300px;
				min-width: 300px;
				min-height: 400px;
				max-height: 400px;
			}
		}
		.text {
			h6 {
				${props => props.theme.media.sdesk} {
					font-size: 1.3rem;
				}
			}
			.title {
				${props => props.theme.media.sdesk} {
					font-weight: 300;
					margin-bottom: 1.1rem;
				}
			}
		}
	}
`

const parseBGColor = (color, colors) =>
	color === 'Blue'
		? colors.skyBlue.light('1').desat('5').val
		: color === 'Salmon'
		? '#E6C5AE'
		: colors.skyBlue.light('1').desat('5').val // this is the default

const parseHeaderBgColor = (color, colors) =>
	color === 'Dark Slate'
		? colors.slate.val
		: color === 'Light Slate'
		? colors.heroSlate.val
		: colors.slate.val // this is the default

const ProfilesSqueeze = ({ data, className }) => {
	const { sectionHeading, headerBgColor, heading, profiles, bgColor } = data

	const { colors } = useTheme()
	const parsedBgColor = parseBGColor(bgColor, colors)
	const parsedHeaderBgColor = parseHeaderBgColor(headerBgColor, colors)

	return (
		<Section
			bgColor={parsedBgColor}
			className={`profiles-section${className ? ` ${className}` : ''}`}
		>
			<SectionHeading headerBgColor={parsedHeaderBgColor}>{sectionHeading}</SectionHeading>
			<H2>{heading}</H2>
			<div className='profiles'>
				{profiles && profiles.map(profile => <Card key={profile.id} profile={profile} />)}
			</div>
		</Section>
	)
}
ProfilesSqueeze.propTypes = {
	data: PropTypes.shape({
		heading: PropTypes.string,
	}),
}
export default ProfilesSqueeze
