import React from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'
import { RT } from '../../../UI'
import SectionHeading from '../Section.Heading'

const Section = styled.section`
	background: ${props => props.theme.colors.white.val};
	.inner {
		padding: 22px ${props => props.theme.sizes.gutter.mobile.px};
		${props => props.theme.media.sdesk} {
			padding: 22px ${props => props.theme.sizes.gutter.sdesk.px};
		}
	}
`
const parseHeaderBgColor = (color, colors) =>
	color === 'Dark Slate'
		? colors.slate.val
		: color === 'Light Slate'
		? colors.heroSlate.val
		: colors.slate.val // this is the default

const RichTextLong = ({ data, className }) => {
	const { sectionHeading, headerBgColor, content } = data
	const { colors } = useTheme()
	const parsedHeaderBgColor = parseHeaderBgColor(headerBgColor, colors)

	return (
		<Section className={`rt-section${className ? ` ${className}` : ''}`}>
			<SectionHeading headerBgColor={parsedHeaderBgColor}>{sectionHeading}</SectionHeading>
			<div className={'inner'}>
				<RT content={content} />
			</div>
		</Section>
	)
}
RichTextLong.propTypes = {
	data: PropTypes.shape({
		content: PropTypes.shape({
			json: PropTypes.shape({}),
		}),
	}),
}
export default RichTextLong
