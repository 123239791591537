import styled from 'styled-components'

const HeroView = styled.section`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: stretch;
color: ${props => props.theme.colors.white.val};
//background: ${props => props.theme.colors.lightBlue.val};
padding: 0;
min-height: 100px !important;
${props => props.theme.media.sdesk} {
  flex-direction: row;
}

.crumb-wrap {
  padding: 0.5em ${props => props.theme.sizes.gutter.mobile.px};
}
.bg-pic {
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 2;
  filter: brightness(20%);
  ${props => props.theme.media.sdesk} {
    max-width: 50%;
  }
  &.full-pic{
    ${props => props.theme.media.sdesk} {
      max-width: 100%;
    }
  }
}

> .content {
  max-width: 100%;
  z-index: 3;
  padding: ${props => props.theme.sizes.gutter.mobile.px};
  ${props => props.theme.media.sdesk} {
    max-width: 50%;
    padding: 80px 50px;
    padding-left: ${props => props.theme.sizes.gutter.sdesk.px};
  }
  ${props => props.theme.media.ldesk} {
    padding: 100px ${props => props.theme.sizes.gutter.sdesk.px};
  }
  .breadcrumbs {
    padding-top: 0;
  }
  .logos {
    max-height: 90px;
    a{
      border:none;
    }
  }
  .h-top {
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 200;
    font-size: 2.5rem;
    letter-spacing: -3px;
    ${props => props.theme.media.sdesk} {
      margin-top: 40px;
    }
  }
  .h-bottom {
    font-size: 1.5rem;
    font-weight: 700;
    ${props => props.theme.media.sdesk} {
      font-size: 1.8rem;
      font-weight: 700;
    }
  }
  p {
    color: ${props => props.theme.colors.white.val};
    font-size: 0.9rem;
    ${props => props.theme.media.sdesk} {
      font-size: 1.5rem;
      line-height: 1.7rem;
      padding-right: 1rem;
      position: relative;
    }
  }
  .cta-wrap {
    text-align: center;
    padding-top: 20px;
    ${props => props.theme.media.sdesk} {
      text-align: left;
    }
    a {
      text-decoration: none;
    }
  }
}

.form-container {
  width: 100%;
  z-index: 3;
  overflow: hidden;
  &.blue-bg{
    background: ${props => props.theme.colors.lightBlue.val};
  }
  .arrow {
    display: none;
    ${props => props.theme.media.sdesk} {
      display: block;
      width: 180px;
      height: 100px;
      opacity: 0.5;
      margin: auto;
      position: absolute;
      left: -80px;
      top: calc(50% - 50px);
      z-index: 2;
    }
  }
  .cta-copy {
    z-index: 1;
    padding: ${props => props.theme.sizes.gutter.mobile.px};
    ${props => props.theme.media.sdesk} {
      padding-left: 100px;
      margin-top: 20%;
      padding-bottom: 200px;
      h3 {
        padding-left: 0;
        color: ${props => props.theme.colors.text.val};
        font-weight: 800;
        font-size: 1.1rem;
        line-height: 1.3rem;
      }
    }
    &.long{
      h3, p{
        color: ${props => props.theme.colors.white.val};
      }
    }

    .copy {
      background: transparent;
      box-shadow: none;
      max-width: 1000px;
      //padding: 0 ${props => props.theme.sizes.gutter.mobile.px};
      ${props => props.theme.media.sdesk} {
        max-width: 620px;
      }
      p {
        color: ${props => props.theme.colors.white.val};
        ${props => props.theme.media.sdesk} {
          color: ${props => props.theme.colors.text.val};
        }
      }
    }
    .cta-wrap {
      display: flex;
      justify-content: center;
      ${props => props.theme.media.sdesk} {
        display:inline-block;
      }
    }
    &.long{
      h3, p{
            color: ${props => props.theme.colors.white.val};
          }
      }
  }
  .hubspot,
  .hubspot__loading {
    z-index: 1;
    background: ${props => props.bgColor};
    &::after {
      background: transparent;
    }
    &.long {
      h3, p, legend{
        color: ${props => props.theme.colors.white.val};
      }
    }
    .bg,
    .bg-blocks {
      display: none;
    }
    .content {
      max-width: 1000px;
      ${props => props.theme.media.sdesk} {
        max-width: 620px;
      }
    }
    .form-title {
      padding: 0;
      display: block;
      font-size: 1.5rem;
      font-weight: 400;
      padding-top: ${props => props.theme.sizes.gutter.mobile.px};
      color: ${props => props.theme.colors.text.val};
      @media (min-width: 1100px) {
        padding: 2em 50px 0 33px;
      }
    }
    .hs-render-target {
      background: transparent;
      box-shadow: none;
      max-width: 1000px;
      //padding: 0 ${props => props.theme.sizes.gutter.mobile.px};
      ${props => props.theme.media.sdesk} {
        max-width: 620px;
        padding: 0 50px 10px 35px;
        min-height: 200px;
      }
      fieldset {
        max-width: 1000px;
      }
      input,
      select {
        padding: 0 15px;
        height: 55px;
        font-size: 1.2rem;
      }

      .form-columns-1 .hs-form-field .hs-input,
      .form-columns-2 .hs-form-field .hs-input,
      .form-columns-3 .hs-form-field .hs-input {
        width: 100%;
      }

      @media only screen and (min-width: 480px) {
        .form-columns-2 .hs-form-field {
          &:first-child {
            padding-right: 10px;
          }
          &:last-child {
            padding-left: 10px;
          }
        }
      }

      /* for loading spinner */
      &>div {
        position: unset;
        .spinner-wrap {
          background: none;
        }
      }

      input[type='submit'].hs-button {
        color: ${props => props.theme.colors.white.val};
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 1px;
        padding: 20px 0;
        background: ${props => props.theme.colors.slate.val};
        border: 1px solid ${props => props.theme.colors.slate.val};
      }
    }
    label,
    span,
    p,
    legend {
      color: ${props => props.theme.colors.text.val};
    }
    h1,
    h2 {
      color: ${props => props.theme.colors.text.val};
    }
  }
}
.__show-mobile {
  ${props => props.theme.media.sdesk} {
    display: none;
  }
}
.__show-desk {
  display: none;
  ${props => props.theme.media.sdesk} {
    display: block;
  }
}
`
export default HeroView
