import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { RT } from '../../../UI'

const Section = styled.section`
	background: ${props => props.theme.colors.white.val};
	padding: 22px ${props => props.theme.sizes.gutter.mobile.px};
	${props => props.theme.media.sdesk} {
		padding: 22px ${props => props.theme.sizes.gutter.sdesk.px};
	}
`

const RichTextSqueeze = ({ data, className }) => {
	const { content } = data
	return (
		<Section className={`rt-section${className ? ` ${className}` : ''}`}>
			<RT content={content} />
		</Section>
	)
}
RichTextSqueeze.propTypes = {
	data: PropTypes.shape({
		content: PropTypes.shape({
			json: PropTypes.shape({}),
		}),
	}),
}
export default RichTextSqueeze
