import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import styled, { useTheme } from 'styled-components'
import { H1, Link, Icon, RatingStars } from '../../../UI'
import SectionHeading from '../Section.Heading'

const Section = styled.section`
	text-align: center;
	background: ${props => props.bg};
	.inner {
		padding: 0;
		${props => props.theme.media.sdesk} {
			padding: 22px ${props => props.theme.sizes.gutter.sdesk.px} 100px
				${props => props.theme.sizes.gutter.sdesk.px};
		}
	}
	.rating-stars {
		display: flex;
		justify-content: center;
		.star {
			height: 25px;
			width: 25px;
			margin: 20px 10px;
			color: #f49164;
			${props => props.theme.media.sdesk} {
				margin: 20px;
				height: 50px;
				width: 50px;
			}
			svg {
				width: 100%;
				height: auto;
			}
		}
	}
	.link {
		font-weight: 600;
		font-size: 2em;
		line-height: 1.1em;
		margin: 30px 0;
		display: block;
		color: ${props => (!props.isWhite ? 'white' : props.theme.colors.orange.val)};
	}

	blockquote,
	cite {
		font-size: 1.2rem;
		font-weight: 400;
		font-style: ${props => (props.isWhite ? 'normal' : 'italic')};
		letter-spacing: 0.45px;
		line-height: 1.4rem;
		color: ${props => props.theme.colors.slate.val};
		text-align: center;
		display: block;
		clear: both;
		${props => props.theme.media.sdesk} {
			font-size: 1.4rem;
			line-height: 1.6rem;
		}
	}
	blockquote {
		margin-bottom: 20px;
		/* font-style: italic; */
		&:before {
			content: '“';
		}
		&:after {
			content: '”';
		}
	}
	cite {
		font-style: ${props => (props.isWhite ? 'normal' : 'italic')};
		font-size: 1rem;
		${props => props.theme.media.sdesk} {
			font-size: 1.2rem;
		}
	}
	.rating-cta {
		color: ${props => props.theme.colors.orange.val};
	}
`

const parseBg = (color, colors) =>
	color === 'White' ? colors.white.val : color === 'Salmon' ? '#E6C5AE' : colors.white.val // this is the default

const parseHeaderBgColor = (color, colors) =>
	color === 'Dark Slate'
		? colors.slate.val
		: color === 'Light Slate'
		? colors.heroSlate.val
		: colors.slate.val // this is the default

const RatingLong = ({ data, className }) => {
	const { rating, sectionHeading, headerBgColor, heading, testimonial, cta, bg } = data
	const { colors } = useTheme()
	const parsedBg = parseBg(bg, colors)
	const parsedHeaderBgColor = parseHeaderBgColor(headerBgColor, colors)
	const isWhite = bg === 'White'
	return (
		<Section
			isWhite={isWhite}
			bg={parsedBg}
			className={`rating-section${className ? ` ${className}` : ''}`}
		>
			<SectionHeading headerBgColor={parsedHeaderBgColor}>{sectionHeading}</SectionHeading>
			<div className={'inner'}>
				<RatingStars rating={rating} />

				{heading && <H1>{heading}</H1>}
				{testimonial.content ? <blockquote>{testimonial.content.text}</blockquote> : null}
				<cite>{testimonial.cite}</cite>
				<cite>{testimonial.citeTitle}</cite>
				{get(cta, 'link') && (
					<Link noStyles to={cta.link} className='rating-cta'>
						{cta.title || cta.link} <Icon type='arrow' />
					</Link>
				)}
			</div>
		</Section>
	)
}
RatingLong.propTypes = {
	data: PropTypes.shape({
		rating: PropTypes.number,
		testimonial: PropTypes.shape({
			cite: PropTypes.string.isRequired,
			citeTitle: PropTypes.string.isRequired,
		}),
	}),
}
export default RatingLong
