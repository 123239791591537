import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import get from 'lodash/get'
import styled, { useTheme } from 'styled-components'
import { H2, H3, CTALink, Link } from '../../../UI'
import SectionHeading from '../Section.Heading'

const Section = styled.section`
	background: ${props => props.theme.colors.skyBlue.light(4).desat().val};
		flex-wrap: no-wrap;
	}
  .inner{
    padding: 22px ${props => props.theme.sizes.gutter.mobile.px} 40px
		${props => props.theme.sizes.gutter.mobile.px};
	${props => props.theme.media.sdesk} {
		padding: 22px ${props => props.theme.sizes.gutter.sdesk.px} 200px
			${props => props.theme.sizes.gutter.sdesk.px};
  }
  }
	h2 {
		text-align: center;
		font-weight: 700;
		font-size: 1.1rem;
		padding: 60px 0;
		${props => props.theme.media.sdesk} {
			font-weight: 600;
			font-size: 2.5em;
		}
	}
  h3 {
		text-align: center;
		font-size: 1.1rem;
	}
	.campuses-wrap {
		display: block;
		text-align: center;
		${props => props.theme.media.sdesk} {
			display: flex;
			flex-wrap: nowrap;
			justify-content: center;
		}
		.campus {
			margin: 20px 8px;
			display: block;
			position: relative;
			color: ${props => props.theme.colors.black.val};
			${props => props.theme.media.sdesk} {
				flex: 1;
			}
			.pic {
				min-height: 150px;
				min-width: 150px;
				width: 100%;
				height: 100%;
				picture {
					img {
						width: 100%;
						height: auto;
					}
				}
			}
			p {
				font-size: 1rem;
				margin: 0.2em 0 0 0;
			}
			.title {
				color: ${props => props.theme.colors.white.val};
				background: ${props => props.theme.colors.slate.val};
				padding: 12px 0;
				margin: -5px 0 0 0;
				letter-spacing: -0.36px;
				font-size: 1em;
				position: absolute;
				bottom: 0;
				width: 100%;

				${props => props.theme.media.sdesk} {
					font-size: 1em;
					margin-bottom: 1em;
					position: relative;
				}
				${props => props.theme.media.ldesk} {
					font-size: 1.2em;
				}
			}
			.info {
				display: none;
				${props => props.theme.media.sdesk} {
					display: block;
				}
			}
		}
	}
`

const parseHeaderBgColor = (color, colors) =>
	color === 'Dark Slate'
		? colors.slate.val
		: color === 'Light Slate'
		? colors.heroSlate.val
		: colors.slate.val // this is the default

const CampusesLong = ({ data, className }) => {
	const { sectionHeading, headerBgColor, campuses, headline, subHeadline, cta } = data
	const { colors } = useTheme()
	const parsedHeaderBgColor = parseHeaderBgColor(headerBgColor, colors)
	return (
		<Section className={`cta-campuses-section${className ? ` ${className}` : ''}`}>
			<SectionHeading headerBgColor={parsedHeaderBgColor}>{sectionHeading}</SectionHeading>
			<div className={'inner'}>
				<H2>{headline}</H2>
				<H3>{subHeadline}</H3>
				<div className={`campuses-wrap`}>
					{campuses.map(({ id, bannerPic, contactInfo, slug, name }) => (
						<Link noStyles key={id} to={`/learning-hubs/${slug}`} className={`campus`}>
							<Img
								className='pic'
								fluid={get(bannerPic, 'fluid')}
								alt={get(bannerPic, 'title', name)}
								objectFit='cover'
								objectPosition='50% 50%'
							/>
							<h3 className={`title`}>{name}</h3>
							<div className={`info`}>
								{get(contactInfo, 'street') ? <p>{contactInfo.street}</p> : null}
								<p>
									{get(contactInfo, 'city')}, {get(contactInfo, 'state')} {get(contactInfo, 'zip')}
								</p>
								{get(contactInfo, 'phone') ? <p>Phone: {contactInfo.phone}</p> : null}
								{get(contactInfo, 'fax') ? <p>Fax: {contactInfo.fax}</p> : null}
							</div>
						</Link>
					))}
				</div>
			</div>
			{cta ? (
				<div className='cta-wrap'>
					<CTALink to={cta.link}>{cta.title}</CTALink>
				</div>
			) : null}
		</Section>
	)
}
CampusesLong.propTypes = {
	data: PropTypes.shape({}),
}
export default CampusesLong
