import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from '../../../UI'

const HeroLogosView = styled.div`
	display: inline-flex;
	align-items: center;
	.partner-logo {
		height: 120px;
		img {
			height: 100%;
			width: auto;
		}
	}
`

const HeroLogos = ({ logo, headingTop }) => {
	return (
		<HeroLogosView className='flex logos'>
			<div className='partner-logo'>
				{logo.link ? (
					<Link to={logo.link}>
						<img src={logo.image.fluid.src} alt={logo?.title || headingTop} />
					</Link>
				) : (
					<img src={logo.image.fluid.src} alt={logo?.title || headingTop} />
				)}
			</div>
		</HeroLogosView>
	)
}
HeroLogos.propTypes = {
	logo: PropTypes.shape({
		image: PropTypes.shape({
			fluid: PropTypes.shape({
				src: PropTypes.string.isRequired,
			}).isRequired,
		}).isRequired,
	}).isRequired,
}
export default HeroLogos
