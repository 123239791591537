import PropTypes from 'prop-types'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Accordion, AccordionProvider, BulletList, H3, Icon, RT } from '../../../UI'
import SectionHeading from '../Section.Heading'


const Section = styled.section`
	background: rgb(208, 225, 231);
	.inner {
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		${props => props.theme.media.sdesk} {
			flex-direction: row;
		}
		padding: 0;
		${props => props.theme.media.sdesk} {
			padding: 63px ${props => props.theme.sizes.gutter.sdesk.px} 83px
				${props => props.theme.sizes.gutter.sdesk.px};
		}
	}
	.accordion-paragraph {
		flex: 1;
		padding: 30px ${props => props.theme.sizes.gutter.mobile.px};
		width: 100%;
		${props => props.theme.media.sdesk} {
			padding: 40px 40px 0 0;
			max-width: 50%;
		}
		p {
			font-size: 1.2rem;
			line-height: 1.4rem;
		}
		h3 {
			font-size: 1.9rem;
			font-weight: 200;
			text-align: center;
			${props => props.theme.media.sdesk} {
				font-size: 2.3rem;
				text-align: left;
			}
		}
	}

	.accordion-container {
		width: 100%;
		${props => props.theme.media.sdesk} {
			width: 45%;
			//position: absolute;
			float: right;
			right: 0;
		}
		.accordion-wrap {
			padding: 40px;
			background: ${props => props.theme.colors.skyBlue.desat('1').light('5').val};
			flex: 1;
			width: 100%;
			${props => props.theme.media.sdesk} {
				//max-width: 648px;
			}
			h3 {
				font-size: 1.5rem;
				font-weight: 200;
				text-align: left;
				margin-bottom: 20px;
				${props => props.theme.media.sdesk} {
					margin-bottom: 40px;
					text-align: center;
					font-size: 2.1rem;
					font-weight: 300;
				}
			}
			.icon {
				position: absolute;
				right: 0;
				top: 0;
				min-width: 400px;
				height: 100%;
				overflow: hidden;
				${props => props.theme.media.sdesk} {
					min-width: initial;
					width: 50%;
					right: auto;
					left: 66%;
				}
				svg {
					width: 100%;
					height: auto;
					position: absolute;
					bottom: 0;
					fill: #a4c8d6;
					opacity: 0.07;
					${props => props.theme.media.sdesk} {
						position: relative;
						height: 100%;
						width: auto;
						max-height: 300px;
					}
				}
			}

			.accordions {
				${props => props.theme.media.sdesk} {
					max-width: 60%;
					margin: 0 auto;
				}

				button {
					font-size: 1rem;
					letter-spacing: 0.5px;
					font-weight: 500;
					text-transform: uppercase;
					${props => props.theme.media.sdesk} {
						font-size: 1.3rem;
					}
					color: ${props => props.theme.colors.slate.val};
					.toggle {
						span {
							height: 3px;
						}
					}
				}
				.accordion-content {
					padding: 0 0 0 36px;
					.rt {
						padding-bottom: 4px;
					}
					a {
						border-color: inherit;
						&:hover {
							border-color: transparent;
						}
					}
					p {
						font-size: 1.5em;
						color: ${props => props.theme.colors.slate.light('1').val};
						${props => props.theme.media.sdesk} {
							font-size: 1.2rem;
							line-height: 1.4rem;
						}
					}
				}
			}
		}
	}
`
const parseHeaderBgColor = (color, colors) =>
	color === 'Dark Slate'
		? colors.slate.val
		: color === 'Light Slate'
		? colors.heroSlate.val
		: colors.slate.val // this is the default

const AccordionLong = ({ data, className }) => {
	const { icon, items, heading, text, sectionHeading, headerBgColor, displayOptions } = data
	const { colors } = useTheme()
	const parsedHeaderBgColor = parseHeaderBgColor(headerBgColor, colors)

	return (
		<Section className={`cta-accordion-section${className ? ` ${className}` : ''}`}>
			<SectionHeading headerBgColor={parsedHeaderBgColor}>{sectionHeading}</SectionHeading>
			<div className={'inner flex'}>
				<div className='accordion-paragraph'>
					<RT className='text' content={text} />
				</div>
				<div className={`accordion-container`}>
					<div className={`accordion-wrap`}>
						{icon && <Icon type={icon.type} />}
						<H3>{heading}</H3>

						{displayOptions === 'Bullet List' ? (
							<BulletList items={items} />
						) : (
							<AccordionProvider>
								<div className={`accordions`}>
									{items.map(({ id, title, content, richText }) => (
										<Accordion key={id} title={title} showPlus id={id}>
											{content ? <RT className='text'>{content.text}</RT> : null}
											{richText ? <RT content={richText} /> : null}
										</Accordion>
									))}
								</div>
							</AccordionProvider>
						)}
					</div>
				</div>
			</div>
		</Section>
	)
}
AccordionLong.propTypes = {
	data: PropTypes.shape({
		heading: PropTypes.string.isRequired,
	}),
}
export default AccordionLong
