import styled from 'styled-components'

export default styled.h1`
	//background: ${props => props.theme.colors.heroSlate.val};
  background: ${props => props.headerBgColor}; 
	position: relative;
	width: 100vw;
	padding: 25px 20px;
	margin-bottom: 30px;
	color: ${props => props.theme.colors.lightCtaBg.val};
	text-transform: uppercase;
  text-align: center;
	font-size: 1.2rem;
  line-height: 1.4rem;
	font-weight: 300;
  z-index:1;
  ${props => props.theme.media.sdesk} {
    font-size: 1.8rem;
  }

	&:after {
		content: '';
		position: absolute;
		left: calc(50% - 50px);
		width: 0;
		height: 0;
		display: table;
		clear: both;
		border-left: 50px solid transparent;
		border-right: 50px solid transparent;
		//border-top: 40px solid ${props => props.theme.colors.heroSlate.val};
		border-top: 50px solid ${props => props.headerBgColor};;
	}
`
