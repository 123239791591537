import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Icon } from '../../UI'

const View = styled.div`
	width: 100%;
	text-align: center;
	overflow: hidden;
	height: 50px;
	${props => props.theme.media.sdesk} {
		height: 100px;
	}
	.arrow {
		width: 50px;
		height: 50px;
		color: ${props => props.theme.colors.white.val};
		opacity: 0.5;
		margin: -40px auto 0 auto;
		transform: rotate(90deg);
		&.is-bg-white {
			color: ${props => props.theme.colors.text.val};
		}
		${props => props.theme.media.sdesk} {
			width: 100px;
			height: 100px;
			//margin: -70px auto 0 -13px;
			margin: -70px auto 0 auto;
		}
	}
`

const SectionArrow = ({ isBgWhite }) => {
	return (
		<View className={`arrow-wrap${isBgWhite ? ' is-bg-white' : ''}`}>
			<Icon type='arrow' />
		</View>
	)
}
SectionArrow.propTypes = {
	isBgWhite: PropTypes.bool,
}
export default SectionArrow
