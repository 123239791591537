import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Img from 'gatsby-image'
// import { useTheme } from 'styled-components'
import { H1, H2, H3, Icon, Link, P, Hubspot, RT } from '../../../UI'
import Breadcrumbs from '../../Breadcrumbs'
import HeroView from './Hero.view'
import HeroLogos from './Hero.logos'
import useCalculatedCSSProperties from '../../../../utils/useCalculatedCSSProperties'

const Hero = ({ data, className, breadcrumbs, pageType, ...props }) => {
	const {
		headingTop,
		headingBottom,
		heroPic,
		logo,
		cta = {},
		text,
		form,
		rightColConfig,
		altText,
	} = data
	// const { colors } = useTheme()
	const { portalId, formId, title } = form || {}
	// const imgPath = get(heroPic, 'fluid.src')

	// example of nested ternary expression
	// this is similar to if(){} else if() {} else if() {} etc.

	// const parsedBgColor =
	// 	bgColor === 'Slate'
	// 		? colors.heroSlate.val
	// 		: bgColor === 'Dark Grey'
	// 		? '#5a5858'
	// 		: bgColor === 'Light Grey'
	// 		? colors.grey.light(7).val
	// 		: bgColor === 'Light Blue'
	// 		? colors.skyBlue.desat(1).light(4).val
	// 		: colors.heroSlate.val // this is the default

	const { padding: suggestedPadding } = useCalculatedCSSProperties()

	return (
		<HeroView
			className={`hero-section${className ? ` ${className}` : ''}`}
			// bgColor={parsedBgColor}
			{...props}
		>
			<div className='content' style={{ padding: suggestedPadding }}>
				{breadcrumbs ? (
					<div className='__show-desk'>
						<Breadcrumbs breadcrumbs={breadcrumbs} />
					</div>
				) : null}
				{logo?.image?.fluid?.src ? <HeroLogos logo={logo} headingTop={headingTop} /> : null}
				{headingTop ? <H2 className='h-top'>{headingTop}</H2> : null}
				{headingBottom ? <H1 className='h-bottom'>{headingBottom}</H1> : null}
				{get(text, 'text') && <P>{text.text}</P>}
				{/* {get(cta, 'link') && (
					<div className={`cta-wrap`}>
						<Link isCta isWhite to={cta.link}>
							{cta.title || cta.link}
						</Link>
					</div>
				)} */}
			</div>

			<div className={`form-container${pageType === 'Squeeze' ? ` blue-bg` : ''}`}>
				{rightColConfig === 'CTA Button' && cta?.link ? (
					<div className={`cta-copy${pageType === 'Long Form' ? ` long` : ''}`}>
						<div className='cta-contents'>
							<H3>{altText.title}</H3>
							<div className='copy'>
								{altText?.content?.text ? <P>{altText.content.text}</P> : null}
								{altText?.richText?.json ? <RT content={altText.richText} /> : null}
								{cta?.link && (
									<div className={`cta-wrap`}>
										<Link className='__show-mobile' isCta isLight to={cta.link}>
											{cta.title || cta.link}
										</Link>
										<Link className='__show-desk' isCta isDark to={cta.link}>
											{cta.title || cta.link}
										</Link>
									</div>
								)}
							</div>
						</div>
					</div>
				) : portalId && formId ? (
					<Hubspot
						className={`${pageType === 'Long Form' ? ` long` : ''}`}
						portalId={portalId}
						formId={formId}
					>
						{title && <H3 className='form-title'>{title}</H3>}
					</Hubspot>
				) : null}

				{pageType === 'Squeeze' ? <Icon type='arrow' /> : null}
			</div>

			<Img
				className={`bg-pic${pageType === 'Long Form' ? ` full-pic` : ''}`}
				fluid={get(heroPic, 'fluid')}
				alt={get(heroPic, 'title', headingTop)}
				objectFit='cover'
				objectPosition='50% 50%'
				style={{ position: 'absolute' }}
			/>
			{breadcrumbs ? (
				<div className='crumb-wrap __show-mobile'>
					<Breadcrumbs breadcrumbs={breadcrumbs} />
				</div>
			) : null}
		</HeroView>
	)
}
Hero.propTypes = {
	data: PropTypes.shape({
		headingTop: PropTypes.string,
		headingBottom: PropTypes.string,
	}).isRequired,
}
export default Hero
