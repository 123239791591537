import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import GenCTA from './Section.CTA.gen'
import SimpleCTA from './Section.CTA.simple'
import ColumnsCTA from './Section.CTA.columns'
import AccordionCTA from './Section.CTA.accordion'

const getCTAComponent = type => {
	switch (type) {
		case 'ContentfulCtaSectionGen':
			return GenCTA
		case 'ContentfulCtaSectionColumns':
			return ColumnsCTA
		case 'ContentfulCtaSectionList':
			return AccordionCTA
		default:
			return SimpleCTA
	}
}

const CTASection = ({ data, ...props }) => {
	const Section = getCTAComponent(
		get(data, 'section.ctaType') === 'Simple' ? 'Simple' : get(data, 'section.internal.type')
	)
	return <Section data={data.section} {...props} />
}
CTASection.propTypes = {
	data: PropTypes.shape({
		section: PropTypes.shape({
			internal: PropTypes.shape({
				type: PropTypes.string.isRequired,
			}).isRequired,
		}).isRequired,
	}).isRequired,
}
export default CTASection
