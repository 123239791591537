import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Link, Icon } from '../../../UI'

const CTA = styled.section`
	.link {
		color: ${props => props.theme.colors.orange.val};
		display: flex;
		align-items: center;
		padding: 0.5em 0;
		.icon {
			margin-left: 0.5em;
		}
	}
`
const LinkCtaLong = ({ data }) => {
	const { link, title } = data
	return link ? (
		<CTA>
			<Link to={link} noStyles>
				{title || link}
				<Icon type='arrow' />
			</Link>
		</CTA>
	) : null
}
LinkCtaLong.propTypes = {
	data: PropTypes.shape({
		title: PropTypes.string,
		link: PropTypes.string,
	}),
}
export default LinkCtaLong
