import { useEffect, useState } from 'react'

/** Constants */

const MOBILE_BREAKPOINT = 600
const TABLET_BREAKPOINT = 1041

/** Component */

/** Provides information on the size of the current device's screen. */
const useWindowSize = () => {
	const [screenWidth, setScreenWidth] = useState<number>(
		typeof window !== 'undefined' ? window.innerWidth : MOBILE_BREAKPOINT
	)

	useEffect(() => {
		const handleWindowResize = () => setScreenWidth(window.innerWidth)
		window.addEventListener('resize', handleWindowResize)
		return () => window.removeEventListener('resize', handleWindowResize)
	}, [])

	return {
		isSmall: screenWidth <= MOBILE_BREAKPOINT,
		isMedium: screenWidth > MOBILE_BREAKPOINT && screenWidth <= TABLET_BREAKPOINT,
		screenWidth,
		mobileBreakpoint: MOBILE_BREAKPOINT,
	}
}

/** Exports */

export default useWindowSize
