import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Hubspot, H3 } from '../../../UI'
import SectionArrow from '../Section.arrow'

const Section = styled.section`
	min-height: ${props =>
		props.isSingle ? `calc(100vh - ${props => props.theme.sizes.header.mobile.px})` : 'initial'};
	${props => props.theme.media.sdesk} {
		min-height: ${props =>
			props.isSingle ? `calc(100vh - ${props => props.theme.sizes.header.sdesk.px})` : 'initial'};
	}
	.hub-section-heading {
		margin-bottom: 0;
	}
	.form-title {
		margin: 30px 0 1em 0;
		font-size: 2rem;
		font-weight: 200;
		text-align: center;
		${props => props.theme.media.sdesk} {
			font-size: 3rem;
		}
	}
	.hubspot {
		padding: ${props => props.theme.sizes.gutter.mobile.px};
		background: ${props => props.theme.colors.white.val};
		${props => props.theme.media.sdesk} {
			padding: 72px ${props => props.theme.sizes.gutter.sdesk.px};
		}
		.bg {
			overflow: hidden;
		}

		.hs-render-target {
			/* background: transparent; */
			/* box-shadow: none; */

			.form-columns-1 .hs-form-field .hs-input,
			.form-columns-2 .hs-form-field .hs-input,
			.form-columns-3 .hs-form-field .hs-input {
				width: 100%;
			}

			@media only screen and (min-width: 480px) {
				.form-columns-2 .hs-form-field {
					&:first-child {
						padding-right: 10px;
					}
					&:last-child {
						padding-left: 10px;
					}
				}
			}

			input[type='submit'].hs-button {
				color: ${props => props.theme.colors.white.val};
				text-transform: uppercase;
				font-weight: 600;
				letter-spacing: 1px;
				padding: 20px 0;
				background: ${props => props.theme.colors.slate.val};
				border: 1px solid ${props => props.theme.colors.slate.val};
			}
		}
	}
`
const HubspotSqueeze = ({ data, className }) => {
	const { form, isSingleSection } = data
	const { portalId, formId, title } = form
	// console.log('form: ', form)
	// console.log('formId: ', formId)
	return (
		<Section
			isSingle={isSingleSection}
			className={`hubspot-section${className ? ` ${className}` : ''}`}
		>
			<SectionArrow isBgWhite />
			<Hubspot portalId={portalId} formId={formId}>
				{title && <H3 className='form-title'>{title}</H3>}
			</Hubspot>
		</Section>
	)
}
HubspotSqueeze.propTypes = {
	data: PropTypes.shape({}),
}
export default HubspotSqueeze
