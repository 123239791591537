import React from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'

import ContactInfo from '../../ContactInfo'
import SectionHeading from '../Section.Heading'

const Section = styled.section`
	background: ${props => props.theme.colors.skyBlue.light(4).desat().val};
	.inner {
		padding: 22px ${props => props.theme.sizes.gutter.mobile.px} 40px
			${props => props.theme.sizes.gutter.mobile.px};
		padding-top: 0;
		${props => props.theme.media.sdesk} {
			padding: 22px ${props => props.theme.sizes.gutter.sdesk.px} 100px
				${props => props.theme.sizes.gutter.sdesk.px};
		}
	}
`

const parseHeaderBgColor = (color, colors) =>
	color === 'Dark Slate'
		? colors.slate.val
		: color === 'Light Slate'
		? colors.heroSlate.val
		: colors.slate.val // this is the default

const ContactInfoLong = ({ data, className }) => {
	const { sectionHeading, headerBgColor } = data
	const { colors } = useTheme()
	const parsedHeaderBgColor = parseHeaderBgColor(headerBgColor, colors)
	return (
		<Section className={`cta-contact-section${className ? ` ${className}` : ''}`}>
			<SectionHeading headerBgColor={parsedHeaderBgColor}>{sectionHeading}</SectionHeading>
			<div className={'inner'}>
				<ContactInfo contactInfo={data} />
			</div>
		</Section>
	)
}
ContactInfoLong.propTypes = {
	data: PropTypes.shape({
		city: PropTypes.string,
	}),
}
export default ContactInfoLong
