import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import { Accordion, AccordionProvider, H2, H3, Link, Icon, BGBlocks } from '../../../UI'

const Section = styled.section`
	background: ${props => props.theme.colors.slate.light().val};
	.bg-blocks {
		z-index: 0;
		display: none;
		position: absolute;
		right: 0;
		top: 0;
		height: 160%;
		pointer-events: none;
		${props => props.theme.media.sdesk} {
			display: block;
			fill: ${props => props.theme.colors.slate.light('5').val};
			opacity: 0.4;
		}
	}
	padding: 22px ${props => props.theme.sizes.gutter.mobile.px};
	color: ${props => props.theme.colors.white.val};
	${props => props.theme.media.sdesk} {
		padding: 22px ${props => props.theme.sizes.gutter.sdesk.px} 100px
			${props => props.theme.sizes.gutter.sdesk.px};
	}
	.icon-wrap-mobile {
		display: block;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 280px;
		height: 280px;
		${props => props.theme.media.sdesk} {
			display: none;
		}
		.icon {
			width: 100%;
			height: auto;
			svg {
				width: 100%;
				height: auto;
				color: #6883a0;
			}
		}
	}
	.inner-wrap {
		padding: 40px 0;
		${props => props.theme.media.sdesk} {
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}

		.left,
		.right {
			flex: 1;
		}
		.left {
			${props => props.theme.media.sdesk} {
				padding-right: 60px;
				max-width: 60vw;
				flex-grow: 1;
			}
			h2 {
				color: #abbcce;
				font-weight: 100;
				font-size: 1.8rem;
				white-space: nowrap;
				${props => props.theme.media.sdesk} {
					font-size: 4.5rem;
					/* font-size: 7.5rem; */
					color: #b8d1ea;
					letter-spacing: -0.02em;
				}
				@media only screen and (min-width: 1550px) {
					font-size: 7.5rem;
				}
			}

			.icon-wrap {
				display: none;
				${props => props.theme.media.sdesk} {
					position: relative;
					display: block;
				}
				.icon {
					width: 250px;
					height: 250px;

					${props => props.theme.media.sdesk} {
						width: 250px;
						height: 250px;
						margin-right: 40px;
					}

					svg {
						width: 100%;
						height: auto;
						color: ${props => props.theme.colors.slate.light('5').val};
					}
				}
			}
			.description-wrap {
				display: none;
				${props => props.theme.media.sdesk} {
					display: block;
					p {
						font-size: 1.5rem;
						line-height: 1.7rem;
					}
				}
			}
		}
		.right {
			${props => props.theme.media.sdesk} {
				padding-left: 60px;
			}
			h3 {
				font-size: 1.1rem;
				font-weight: 600;
				${props => props.theme.media.sdesk} {
					font-size: 2rem;
				}
			}
			.accordions {
				.toggle {
					display: flex;

					span {
						background: ${props => props.theme.colors.white.val};
					}
					${props => props.theme.media.sdesk} {
					}
				}

				button {
					font-size: 0.875em;
					letter-spacing: 0.5px;
					${props => props.theme.media.sdesk} {
						font-size: 1.2rem;
					}
				}
				.accordion-content {
					padding: 0 0 0 20px;
					.rt {
						padding-bottom: 4px;
					}
					a {
						border-color: inherit;
						&:hover {
							border-color: transparent;
						}
					}
					p {
						font-size: 0.875em;
						color: inherit;
						${props => props.theme.media.sdesk} {
							font-size: 1rem;
							line-height: 1.2rem;
						}
					}
				}
			}
		}
	}
`

const AccordionCTASection = ({ data, className }) => {
	const { cta, description, caption, accordion, icon } = data

	return (
		<Section className={`cta-accordion-section${className ? ` ${className}` : ''}`}>
			<BGBlocks />
			{icon && (
				<div className={`icon-wrap-mobile`}>
					<Icon type={icon.type} key={get(icon, 'icon.id')} />
				</div>
			)}
			<div className={`inner-wrap flex-desk`}>
				<div className={`left`}>
					<H2>{caption}</H2>
					<div className={`flex`}>
						{icon && (
							<div className={`icon-wrap`}>
								<Icon type={icon.type} key={get(icon, 'icon.id')} />
							</div>
						)}
						<div className={`description-wrap`}>
							{description && <p>{description.text}</p>}
							{get(cta, 'link') && (
								<Link isCta isLight to={cta.link}>
									{cta.title || cta.link}
								</Link>
							)}
						</div>
					</div>
				</div>
				<div className={`right`}>
					<H3>{accordion.heading}</H3>
					<div className={`accordions`}>
						<AccordionProvider>
							{accordion.items.map(({ id, title, content }) => (
								<Accordion key={id} title={title} showPlus id={id}>
									<div
										className='accordion-rt'
										dangerouslySetInnerHTML={{ __html: content.text }}
									/>
								</Accordion>
							))}
						</AccordionProvider>
					</div>
				</div>
			</div>
		</Section>
	)
}
AccordionCTASection.propTypes = {}
export default AccordionCTASection
